import { useEffect, useRef } from "react";
// import { Breakpoints } from "react-device-breakpoints";
import { useMediaQuery } from "react-responsive";
import "./styles/App.css";
// media
import logo from "./images/logo.svg";
import video from "./images/comfortreel.mp4";
import videoMobile from "./images/comfortreel-mobile.mp4";
// hooks
import useToggle from "../hooks/useToggle";

function App() {
	const videoRef = useRef(null);
	const [watch, setWatch] = useToggle();
	const isMobile = useMediaQuery({
		query: "(max-width: 575px	)",
	});
	useEffect(() => {
		function resizedw() {
			const width = window.innerWidth;
			if (width <= 575) {
				videoRef.current.load();
			} else {
				videoRef.current.load();
			}
		}
		var doit;
		window.onresize = function () {
			clearTimeout(doit);
			doit = setTimeout(resizedw, 100);
		};
	}, []);
	return (
		<div className="main-app">
			<section
				className={
					"d-flex flex-column align-items-center justify-content-between" +
					(watch ? " watching" : "")
				}
			>
				<div className="top-section"></div>
				<div
					className="center-section d-flex flex-column align-items-center"
					style={{ padding: "1.5em" }}
				>
					<img
						draggable="false"
						src={logo}
						alt="Comfort Oasis"
						width="170"
						style={{ marginBottom: "1em" }}
					/>
					<p className="text-uppercase text-center">
						Creative studio based in Copenhagen, <br />
						working with clients in both cultural and commercial fields.
						<br />
						Specializing in Art direction, Motion graphics &amp; Video
						production.
					</p>
					<div className="d-flex">
						<a
							href="https://www.instagram.com/comfortoasis/"
							rel="noreferrer noopener"
							target="_blank"
						>
							<button className="btn btn-secondary-green-hover">
								Instagram
							</button>
						</a>
						<a
							href="mailto:hello@comfortoasis.studio"
							rel="noreferrer noopener"
							target="_blank"
						>
							<button
								style={{ marginLeft: "1em" }}
								className="btn btn-secondary-green-hover"
							>
								Contact us
							</button>
						</a>
					</div>
				</div>
				<div className="bottom-section"></div>
			</section>
			<div className="button-section">
				{watch ? (
					<button
						onClick={() => {
							let video = document.querySelector("video");
							setWatch();
							video.muted = !video.muted;
							console.log(video.muted);
						}}
						style={{ marginBottom: "1em" }}
						className="btn btn-secondary-white text-uppercase border-0"
					>
						Back
					</button>
				) : (
					<button
						onClick={() => {
							let video = document.querySelector("video");
							setWatch();
							video.muted = !video.muted;
							console.log(video.muted);
						}}
						style={{ marginBottom: "1em" }}
						className="btn btn-secondary-white text-uppercase border-0"
					>
						Watch video
					</button>
				)}
			</div>
			<div className="video-container">
				<video ref={videoRef} playsInline autoPlay loop muted>
					<source src={!isMobile ? video : videoMobile} type="video/mp4" />
				</video>
			</div>
		</div>
	);
}

export default App;
